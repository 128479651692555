import React from 'react';
import './pages.scss';

export function Psicoterapia(): JSX.Element {
  return (
    <div className='psicoterapiaContainer container' id='psicoterapia'>
      <div className='containerTitle'>
        <div className='title'>PSICOTERAPIA</div>
        <div className='containerText'>
          Psicoterapia é um espaço seguro e confidencial para explorar suas emoções, pensamentos e experiências. Como psicanalista, minha abordagem
          visa compreender as raízes profundas de seus desafios emocionais e ajudá-lo a desenvolver um maior autoconhecimento. Juntos, trabalharemos
          para identificar padrões de comportamento, superar obstáculos e encontrar maneiras saudáveis de lidar com as complexidades da vida. A
          psicoterapia é uma jornada de autodescoberta e crescimento pessoal, onde você pode encontrar apoio e orientação para viver uma vida mais
          equilibrada e significativa
        </div>
      </div>
    </div>
  );
}
