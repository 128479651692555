import React, { useEffect, useState } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './pages.scss';
import { Link } from '@mui/material';

export function TopMenu(): JSX.Element {
  const [scrolled, setScrolled] = useState(false);
  const [scrolledMobile, setScrolledMobile] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }

      if (window.scrollY > 150) {
        setScrolledMobile(true);
      } else {
        setScrolledMobile(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`nav ${scrolled ? 'nav-scrolled' : ''}`}>
        <ul className='top-menu' id='top-menu'>
          <li>
            <a href='#sobre-mim'>SOBRE MIM</a>
          </li>
          <li>
            <a href='#psicoterapia'>PSICOTERAPIA</a>
          </li>
          <li>
            <a href='#duvidas'>DÚVIDAS</a>
          </li>
          <li>
            <a href='#contato'>CONTATO</a>
          </li>
        </ul>
      </div>

      <div className={`nav-mobile ${scrolledMobile ? 'nav-mobile-scrolled' : ''}`}>
        <div className='hamburguer'>
          <nav role='navigation'>
            <div id='menuToggle'>
              <input type='checkbox' />
              <span></span>
              <span></span>
              <span></span>
              <ul id='menu'>
                <a href='#sobre-mim'>
                  <li>Sobre mim</li>
                </a>
                <a href='#psicoterapia'>
                  <li>Psicoterapia</li>
                </a>
                <a href='#duvidas'>
                  <li>Dúvidas</li>
                </a>
                <a href='#contato'>
                  <li>Contato</li>
                </a>
              </ul>
            </div>
          </nav>
        </div>
        <div className='grid-container'>
          <div className='centered-content semi-logo'>
            <span className='nome'>Victória Matos</span>
            <span className='sub-title'>PSICÓLOGA CLÍNICA</span>
            <span className='crp'>CRP-04/70514</span>
          </div>
        </div>
        <Link
          target='_blank'
          href='https://api.whatsapp.com/send?phone=553187608101&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20as%20consultas%20com%20voc%C3%AA!'
          sx={{ pl: 1, pr: 1 }}
        >
          <div className='whatsapp'>
            <WhatsAppIcon />
          </div>
        </Link>
      </div>
    </>
  );
}
