import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { Twitter } from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box } from '@mui/material';

export function Footer(): JSX.Element {
  return (
    <Box
      component='footer'
      sx={{
        backgroundColor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800]),
        p: 6,
      }}
    >
      <Container maxWidth='lg'>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <div className='grid-container-footer'>
              <div className='centered-content semi-logo'>
                <span className='nome'>Victória Matos</span>
                <span className='sub-title'>PSICÓLOGA CLÍNICA</span>
                <span className='crp'>CRP-04/70514</span>
              </div>
            </div>
          </Grid>
          <Grid textAlign={'center'} item xs={12} sm={6}>
            <Typography variant='h6' color='text.primary' gutterBottom>
              Entre em contato e agende uma sessão
            </Typography>
            <Link
              target='_blank'
              href='https://api.whatsapp.com/send?phone=553187608101&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20as%20consultas%20com%20voc%C3%AA!'
              sx={{ pl: 1, pr: 1 }}
            >
              <div className='whatsapp'>
                <WhatsAppIcon />
              </div>
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant='body2' color='text.secondary' align='center'>
            {'Copyright © '}
            <Link color='inherit' href='https://psivictoriamatos.com/'>
              Victória Matos
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
