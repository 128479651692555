import React from 'react';
import './App.scss';
import LinesSVG from './assets/lines.svg';
import { Header } from './pages/header';
import { AboutMe } from './pages/aboutMe';
import { TopMenu } from './pages/topMenu';
import { Psicoterapia } from './pages/psicoterapia';
import { Duvidas } from './pages/duvidas';
import { Footer } from './pages/footer';

function App() {
  return (
    <div>
      <div className='backgroundLines'>
        <img src={LinesSVG} />
      </div>
      <TopMenu />
      <Header />
      <AboutMe />
      <Psicoterapia />
      <Duvidas />
      <Footer />
    </div>
  );
}

export default App;
