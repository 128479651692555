import React from 'react';
import './pages.scss';

export function Header(): JSX.Element {

  return (
    <div className='headerContainer'>
      <span className='nome'>Victória Matos</span>
      <span className='sub-title'>PSICÓLOGA CLÍNICA</span>
      <span className='crp'>CRP-04/70514</span>
    </div>
  );
};
