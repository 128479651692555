import React from 'react';
import './pages.scss';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export function Duvidas(): JSX.Element {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className='blockBackground'>
      <div className='duvidasContainer container' id='duvidas'>
        <div className='containerTitle'>
          <div className='title'>Dúvidas</div>

          <Accordion className='accordion-first' expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
              <Typography>Como sei se preciso de terapia?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Você pode considerar a terapia se estiver enfrentando dificuldades emocionais, problemas de relacionamento, estresse, ansiedade,
                depressão, ou simplesmente se estiver buscando crescimento pessoal e autoaperfeiçoamento.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
              <Typography>Qual é a diferença entre psicanálise e outras abordagens terapêuticas?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A psicanálise é uma abordagem terapêutica que se concentra em explorar os processos inconscientes e a história de vida de uma pessoa.
                Outras abordagens terapêuticas, como a terapia cognitivo-comportamental (TCC), têm focos diferentes, como mudanças de pensamento e
                comportamento.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className='accordion-last' expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls='panel3d-content' id='panel3d-header'>
              <Typography>A terapia é confidencial?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Sim, a terapia é confidencial. As informações compartilhadas nas sessões são protegidas por sigilo profissional, a menos que haja uma
                preocupação de segurança séria que exija ação.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
